<template>
  <div class="app-download">
    <div class="app-download-main">
      <section class="section-item section-item-1">
        <div class="phone-box">
          <div class="phone-img">
            <img src="../../assets/images/km/item-1-phon.png" alt="" />
          </div>
          <div class="phone-footer">
            <a v-if="systemAndroidDownloadUrl" :href="systemAndroidDownloadUrl" class="phone-footer-icon android" target="_blank"></a>
            <a v-if="systemIosDownloadUrl" :href="systemIosDownloadUrl" class="phone-footer-icon ios" target="_blank"></a>
          </div>
        </div>
        <div class="phone-qr">
          <h3 class="qr-title">app điện thoại</h3>
          <h3 class="qr-desc">Tiện Dụng - Dễ Chơi - Dễ Thắng</h3>
          <div class="qr-body">
            <div class="qr-img">
              <img src="../../assets/images/km/item-1-phon.png" alt="" />
            </div>
            <ul class="qr-list">
              <li class="qr-li qr-li-1">
                <i class="qr-li-icon1"></i
                ><span class="qr-li-text">đa dạng chủng loại game</span>
              </li>
              <li class="qr-li qr-li-2">
                <i class="qr-li-icon2"></i
                ><span class="qr-li-text"
                  >thích hợp với mọi thiết bị smart phone</span
                >
              </li>
              <li class="qr-li qr-li-3">
                <i class="qr-li-icon3"></i
                ><span class="qr-li-text"
                  >bất cứ lúc nào bất cứ nơi đâu muốn là chơi</span
                >
              </li>
            </ul>
          </div>
        </div>
      </section>
      <section class="section-item section-item-2">
        <div class="phone-box">
          <div class="phone-img">
            <img src="../../assets/images/km/item-2-phone.png" alt="" />
          </div>
        </div>
        <div class="phone-qr">
          <h3 class="qr-title">trực tuyến</h3>
          <h3 class="qr-title">1000+ Games</h3>
          <p class="qr-desc">Xổ số, Slots, Casino</p>
          <p class="qr-text">Luôn có trò chơi thích hơp với bạn</p>
        </div>
      </section>
      <section class="section-item section-item-3">
        <div class="phone-box">
          <div class="phone-img">
            <img src="../../assets/images/km/item-3-phone.png" alt="" />
          </div>
        </div>
        <div class="phone-qr">
          <h3 class="qr-title">Ưu đãi,</h3>
          <h3 class="qr-title">khuyến mãi</h3>
          <h3 class="qr-title">Không ngừng</h3>
          <p class="qr-text">
            Khuyến mãi dành riêng cho di động luôn luôn đợi bạn
          </p>
        </div>
      </section>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {

    };
  },
 computed: {
    ...mapGetters(["systemIosDownloadUrl", "systemAndroidDownloadUrl"]),
  },
  mounted() {
  },
  methods: {

  },
};
</script>
<style lang="less" scoped>
.app-download {
  background: url(../../assets/images/km/downBanner.png) no-repeat top center /
    100% auto;
  img {
    border: 0;
    vertical-align: middle;
    display: block;
  }
  .app-download-main {
    width: 1200px;
    margin: 0 auto;
    overflow: hidden;
    padding-bottom: 100px;
    .section-item {
      margin-top: 124px;
      display: flex;
      justify-content: space-between;
      .phone-box {
        .phone-img {
          height: 563px;
          img {
            height: inherit;
          }
        }
        .phone-footer {
          display: flex;
          justify-content: center;
          .phone-footer-icon {
            width: 146px;
            height: 55px;
          }
          .android {
            background: url("../../assets/images/km/android.png") no-repeat
              center / cover;
          }
          .ios {
            background: url("../../assets/images/km/ios.png") no-repeat center /
              cover;
            margin-left: 58px;
          }
          a {
            background: transparent;
            text-decoration: none;
            -webkit-tap-highlight-color: transparent;
            color: #0088cc;
          }
        }
      }
      .phone-qr {
        flex: 1;
        margin-left: 20px;
        box-sizing: border-box;
        padding-top: 110px;
        .qr-title {
          padding-left: 109px;
          font-size: 50px;
          height: 50px;
          font-weight: 700;
          color: #f7bd00;
          text-transform: uppercase;
          margin: 0 0;
        }
        .qr-desc {
          padding-left: 109px;
          font-size: 25px;
          height: 25px;
          font-weight: 400;
          color: #f7bd00;
          text-transform: uppercase;
          margin: 43px 0;
        }
        .qr-body {
          display: flex;
          align-items: stretch;
          .qr-img {
            width: 197px;
            img {
              width: 100%;
            }
          }
          .qr-list {
            flex: 1;
            margin-left: 54px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            margin: 0 0 0 54px;
            padding: 0;
            list-style: none outside none;
            .qr-li {
              font-weight: 400;
              font-size: 20px;
              text-transform: uppercase;
              color: #ffffff;
              display: flex;
              align-items: center;
              margin: 0;
              padding: 0;
              .qr-li-text {
                margin-left: 22px;
              }
              .qr-li-icon1 {
                width: 40.39px;
                height: 39px;
                background-image: url("../../assets/images/km/game-icon.png");
              }
              .qr-li-icon2 {
                width: 43.66px;
                height: 37.64px;
                background-image: url("../../assets/images/km/phone-icon.png");
              }
              .qr-li-icon3 {
                width: 44px;
                height: 44px;
                background-image: url("../../assets/images/km/money-icon.png");
              }
            }
          }
        }
      }
    }
    .section-item-2 ,.section-item-3 {
      .phone-img {
        height: 583px;
      }
      .phone-qr {
        text-align: center;
        .qr-title{
          padding-left: 0px;
        }
        .qr-desc {
          padding-left: 0px;
          text-align: center;
          font-weight: 400;
          font-size: 35px;
          color: #f7bd00;
          text-transform: uppercase;
          margin: 43px 0;
        }
        .qr-text {
          padding-left: 0px;
          text-align: center;
          font-weight: 400;
          font-size: 30px;
          color: #ffffff;
          width: 60%;
          margin: 30px auto 0;
          text-transform: uppercase;
        }
      }
    }
  }
}
</style>